<template>
  <div>
    <van-cell-group title="来源渠道">
      <!-- <van-field 
        v-model="orderForm.city_name" 
        label="城市" 
        required 
        placeholder="请选择城市" 
        @click="citys.show = true" 
        :rules="[{ required: true, message: '请选择城市' }]" 
        readonly 
        is-link
      />
      <van-action-sheet v-model="citys.show" :actions="citys.data" @select="onSelectCity" /> -->
      <!-- <SelectItem label="城市" :value="orderForm.city_name" :options="citys.data" @onSelect="onSelectCity" /> -->
      
      <!-- <SelectItem label="来源" :value="orderForm.ly_name" :options="lys.data" @onSelect="onSelectLy" v-if="!orderForm.brid" /> -->
      <!-- <SelectItem label="渠道" :value="orderForm.ly_name" :options="qds.data" @onSelect="onSelectQd" v-if="!orderForm.brid" /> -->
      <van-field 
        v-model="orderForm.city_name" 
        label="城市" 
        required 
        placeholder="请选择城市" 
        @click="citys.show = true" 
        :rules="[{ required: true, message: '请选择城市' }]" 
        readonly 
        is-link
      />
      <van-action-sheet v-model="citys.show" :actions="citys.data" @select="onSelectCity" />

      <van-field 
        v-model="orderForm.ly_name" 
        label="来源" 
        required 
        placeholder="请选择来源" 
        @click="lys.show=true" 
        :rules="[{ required: true, message: '请选择来源' }]" 
        readonly 
        is-link
        v-if="!orderForm.brid"
      />
      <!-- v-if="!orderForm.brid" -->
      <van-action-sheet v-model="lys.show" :actions="lys.data" @select="onSelectLy" />

      <van-field
        v-model="orderForm.qd_name" 
        label="渠道"  
        placeholder="请选择渠道" 
        @click="openQd" 
        required
        :rules="[{ required: true, message: '请选择渠道' }]" 
        readonly 
        is-link
        v-if="!orderForm.brid"
      />
      <!-- v-if="!orderForm.brid" -->
      <van-action-sheet v-model="qds.show" :actions="qds.data" @select="onSelectQd" />
      <SelectItem label="项目负责人" :value="orderForm.xmfzrymc" :options="customers" @onSelect="onSelectCustomer" v-if="orderForm.ly_name === '大客户部'" />
      <SelectPro 
        title="合伙人" 
        placeholder="请输入姓名、手机号搜索"
        :options="partners"
        :value="orderForm.hhr_name"
        @onSearch="getPartner" 
        @onSelect="onSelectPartner"
        v-if="orderForm.qd_name === '合伙人'"
      />
      <van-field
        v-model="orderForm.hhrtjrxm" 
        label="推荐人"  
        placeholder="请选择合伙人" 
        required
        :rules="[{ required: true, message: '请选择合伙人' }]" 
        readonly 
        v-if="orderForm.qd_name === '合伙人'"
      />

      <SelectPro 
        title="合伙平台" 
        placeholder="请输入关键词搜索"
        :options="platforms.options"
        :value="orderForm.hzpt_name"
        @onSearch="onSearchPlatform" 
        @onSelect="onSelectPlatform"
        v-if="orderForm.qd_name === 'TPA'"
      />

      
    </van-cell-group>
    <van-cell-group title="转介绍关系" v-if="orderForm.qd_name=='客户转介绍'||orderForm.qd_name=='员工转介绍'">
       <!-- v-if="form.ly_name=='转介绍'||form.ly_name=='员工介绍'||form.ly_name=='个人转介绍'||form.ly_name=='员工转介绍'" -->
      <van-field
        v-model="orderForm.jsrxm"
        required
        :rules="[{ required: true, message: '请选择介绍人' }]"
        label="介绍人姓名"
        placeholder="请选择介绍人"
        readonly
        @click="jsr.show=true"
        v-if="orderForm.qd_name=='客户转介绍'"
      >
        <template #button>
          <van-button size="small" type="primary" @click="jsr.show=true" native-type="button" v-if="orderForm.qd_name=='客户转介绍'">选择患者</van-button>
        </template>
      </van-field>
      <van-field
        v-model="orderForm.jsrxm"
        required
        :rules="[{ required: true, message: '请选择介绍人' }]"
        label="介绍人姓名"
        placeholder="请选择介绍人"
        readonly
        @click="yg.show=true"
        v-if="orderForm.qd_name=='员工转介绍'"
      >
        <template #button>
          <van-button size="small" type="primary" @click="yg.show=true" native-type="button" v-if="orderForm.qd_name=='员工转介绍'">选择员工</van-button>
        </template>
      </van-field>
      <!-- <van-field 
        v-model="orderForm.jsrxm" 
        label="介绍人姓名" 
        placeholder="请输入介绍人姓名" 
        required 
        :rules="[{ required: true, message: '请输入介绍人姓名！' }]"
        v-if="orderForm.qd_name=='非就诊顾客' || orderForm.qd_name=='开发人直系家属' || orderForm.qd_name=='开发人旁系家属'|| orderForm.qd_name=='开发人朋友'|| orderForm.qd_name=='开发人其他关系'"
      /> -->
      <!-- v-if="form.qd_name=='非就诊顾客'" -->
      <van-field 
        v-model="orderForm.jsrsjh" 
        label="介绍人手机号" 
        placeholder="请输入介绍人手机号码" 
        v-if="orderForm.qd_name=='客户转介绍'||orderForm.qd_name=='员工转介绍'"
      />
      <van-field 
        v-model="orderForm.yhzgx" 
        label="是患者的" 
        required 
        placeholder="请选择介绍人是患者的关系" 
        @click="hz_gx.show = true" 
        :rules="[{ required: true, message: '请选择介绍人是患者的关系' }]" 
        readonly 
        is-link
      />
      <van-action-sheet 
        v-model="hz_gx.show" 
        :actions="hz_gx.data" 
        @select="hz_gx_onselect" 
      />
      <!-- <van-field 
        v-model="orderForm.ykfrgx" 
        label="是开发人的" 
        required 
        placeholder="请选择是开发人的关系" 
        @click="kfr_gx.show = true" 
        :rules="[{ required: true, message: '请选择是开发人的关系' }]" 
        readonly 
        is-link
        v-if="orderForm.ly_name == '员工转介绍' && orderForm.qd_name!='开发人本人'"
      /> -->
      <!-- <van-action-sheet 
        v-model="kfr_gx.show" 
        :actions="kfr_gx.data" 
        @select="kfr_gx_onselect" 
      /> -->
      
      <!-- <van-field
        v-model="orderForm.dzys_name"
        label="点诊医生"
        placeholder="请选择点诊医生"
        readonly
        @click="getDzys()"
        v-if="orderForm.qd_name=='医生老客'"
      >
        <template #button>
          <van-button size="small" type="primary" @click="dzys.show=true" native-type="button">选择点诊医生</van-button>
        </template>
      </van-field> -->
      <!-- <van-field
        v-model="orderForm.dzzjzl_name"
        required
        :rules="[{ required: true, message: '请选择专家助理' }]"
        label="专家助理"
        placeholder="请选择专家助理"
        readonly
        @click="getZjzl()"
        v-if="orderForm.qd_name=='专家助理老客'"
      >
        <template #button>
          <van-button size="small" type="primary" @click="zjzl.show=true" native-type="button">选择专家助理</van-button>
        </template>
      </van-field> -->
      <!-- <van-field 
        v-model="form.jg_name" 
        label="点诊医生" 
        required 
        placeholder="请选择点诊医生" 
        @click="hospitals.show = true" 
        :rules="[{ required: true, message: '请选择点诊医生' }]" 
        readonly 
        is-link
      />
      <van-action-sheet 
        v-model="hospitals.show" 
        :actions="hospitals.data" 
        @select="hospital_onselect" 
      /> -->
    </van-cell-group>
    
    <!-- 介绍人筛选 -->
    <van-popup
      v-model="jsr.show"
      closeable
      close-icon="close"
      position="bottom"
      :style="{ height: '100%'}"
    >
      <div class="popup_top">
        <div class="title">
          选择患者
        </div>
        <form action="/">
          <van-search
            v-model="jsr_key"
            shape="round"
            background="#38b6ab"
            @search="getJsr()"
            placeholder="请输入姓名或手机号码搜索"
          >
          </van-search>
        </form>

      </div>
      
      <van-list class="jsr">
        <van-cell v-for="item in jsr.data" :key="item.id" :title="item.name" :value="item.ycsjhm" @click="confirmJsr(item)"/>
      </van-list>
    </van-popup>
    <!-- 点诊医生筛选 -->
    <!-- <van-popup
      v-model="dzys.show"
      closeable
      close-icon="close"
      position="bottom"
      :style="{ height: '100%'}"
    >
      <div class="popup_top">
        <div class="title">
          选择医生
        </div>
        <form action="/">
          <van-search
            v-model="dzys_key"
            shape="round"
            background="#38b6ab"
            @search="getDzys()"
            placeholder="请输入姓名搜索"
          >
          </van-search>
        </form>

      </div>
      
      <van-list class="dzys">
        <van-cell v-for="item in dzys.data" :key="item.id" :title="item.name" :value="item.jgmcs" @click="confirmDzys(item)"/>
      </van-list>
    </van-popup> -->
    <!-- 点诊专家助理筛选 -->
    <!-- <van-popup
      v-model="zjzl.show"
      closeable
      close-icon="close"
      position="bottom"
      :style="{ height: '100%'}"
    >
      <div class="popup_top">
        <div class="title">
          选择专家助理
        </div>
        <form action="/">
          <van-search
            v-model="zjzl_key"
            shape="round"
            background="#38b6ab"
            @search="getZjzl()"
            placeholder="请输入姓名搜索"
          >
          </van-search>
        </form>

      </div>
      
      <van-list class="dzys">
        <van-cell v-for="item in dzys.data" :key="item.id" :title="item.name" :value="item.jgmcs" @click="confirmZjzl(item)"/>
      </van-list>
    </van-popup> -->
    <!-- 员工 -->
    <van-popup
      v-model="yg.show"
      closeable
      close-icon="close"
      position="bottom"
      :style="{ height: '100%'}"
    >
      <div class="popup_top">
        <div class="title">
          选择员工
        </div>
        <form action="/">
          <van-search
            v-model="yg_key"
            shape="round"
            background="#38b6ab"
            @search="getYg()"
            placeholder="请输入姓名搜索"
          >
          </van-search>
        </form>

      </div>
      
      <van-list class="dzys">
        <van-cell v-for="item in yg.data" :key="item.rybm" :title="item.name" :value="item.jgmcs" @click="confirmYg(item)"/>
      </van-list>
    </van-popup>
  </div>
</template>

<script>
// @ is an alias to /src
import SelectPro from '../components/SelectPro.vue'
import SelectItem from './SelectItem.vue'

export default {
  name: "lyqd",
  components: { SelectPro,SelectItem },
  props:{
    orderForm:Object,
    dicts:Array,
  },
  data() {
    return {
      // dicts:[],
      citys: {
        show:false,
        data:[]
      },
      lys: {
        show:false,
        data:[]
      },
      qds: {
        show:false,
        data:[],
        all:[]
      },

      jsr:{
        show:false,
        data:[],
      }, //介绍人
      jsr_key:'', //介绍人搜索关键词
      dzys: {
        show:false,
        data:[]
      },
      dzys_key:'',
      zjzl: {
        show:false,
        data:[]
      },
      yg_key:'',
      yg: {
        show:false,
        data:[]
      },
      zjzl_key:'',
      //介绍人是患者的关系
      hz_gx: {
        show:false,
        data:[]
      },
      //介绍人是开发人的关系
      kfr_gx: {
        show:false,
        data:[]
      },
      //合伙人
      partners:[],
      //合作平台
      platforms:{
        options:[],
        all:[]
      },
      customers:[],
      // form: {
      //   city_code:'',
      //   city_name:'',
      //   lybm: "", //来源编码
      //   ly_name:"",
      //   qdbm: "", //渠道编码
      //   qd_name:"",
        
      //   jsrbm:'', // 介绍人病人id
      //   jsrxm:'', // 介绍人姓名
      //   jsrsjh:'', // 介绍人手机号
      //   yhzgxbm:'', // 介绍人关系编码
      //   yhzgx:'', // 介绍人关系名称(不传)
      //   dzysbm:'', // 点诊医生
      //   dzys_name:'', // 点诊医生姓名(不传)
      //   dzzjzlbm:'', // 点诊专家助理
      //   dzzjzl_name:'', // 点诊专家助理姓名(不传)
      //   ykfrgxbm:'', // 与开发人关系id
      //   ykfrgx:'', // 与开发人关系名称(不传)
      //   hzptid:'', //合作平台id
      //   hzpt_name:'', //合作平台名称

      //   // hhrid:'', //合伙人id
      //   // hhr_name:'', //合伙人姓名
      //   // hhrtrjbm:'', //合伙人推荐人编码
      //   // hhrtjrxm:'', //合伙人推荐人姓名
      // },
    };
  },
  created() {
    // this.getDate();
    setTimeout(()=>{
      this.getCity();
      // this.getDict()
    },1000)
    setTimeout(()=>{
      this.getLy();
    },1500)
    setTimeout(()=>{
      this.getQd();
      // this.getDict()
    },2000)
  },
  methods: {
    getDict(){
      this.$http.get('dict').then((res)=>{
        if(res.data.code == 200){
          this.dicts = res.data.data
        }
      })
    },

    getJsr(){
      this.$emit('change','jsrbm','')
      // this.form.name = "";
      this.jsr.data = [];
      if(this.jsr_key.length < 2){
        this.$dialog.alert({
          message: '搜索内容不得少于2个字符。',
          theme: 'round-button',
        }).then(() => {
          return false;
        });
      }
      let form = {
        keywords:this.jsr_key
      }
      this.$http.post("his_patient", form).then((res) => {
        if(res.data.code != 200){
          this.$dialog.alert({
            message: '未查到用户信息。',
            theme: 'round-button',
          }).then(() => {
            return false;
          });
        }
        this.jsr.data = res.data.data
      });
    },
    confirmJsr(patient) {
      this.$emit('change','jsrbm',patient.brid)
      this.$emit('change','jsrxm',patient.name)
      this.$emit('change','jsrsjh',patient.sjhm)
      // this.form.jsrbm = patient.brid;
      // this.form.jsrxm = patient.name;
      // this.form.jsrsjh = patient.sjhm;
      this.jsr.show = false;
    },

    onSelectCity(item){
      this.citys.show = false
      this.$emit('change','city_code',item.city_code)
      this.$emit('change','city_name',item.name)

      this.$emit('change','qdbm','')
      this.$emit('change','jgbm','')

      this.getQdOptions()
    },
    getCity(){
      this.$http.get("city").then((res) => {
        if (res.data.code == 200) {
          this.citys.data = res.data.data;
        }
      });
    },
    //来源
    getLy() {
      this.$http.get("ly").then((res) => {
        if (res.data.code == 200) {
          this.lys.data = res.data.data;
        }
      });
    },
    openQd(){
      if(!this.orderForm.city_code){
          this.$dialog.alert({
          message: '请先选择城市！',
          theme: 'round-button',
        })
        return false;
      }
      if(!this.orderForm.lybm){
          this.$dialog.alert({
          message: '请先选择来源！',
          theme: 'round-button',
        })
        return false;
      }
      this.qds.show=true

    },
    onSelectLy(item) {
      this.lys.show = false
      this.$emit('change','lybm',item.lybm)
      this.$emit('change','ly_name',item.name)
      // this.$emit('change','qdbm','')
      // this.$emit('change','qd_name','')

      this.getQdOptions();
      
      //大客户
      if(item.name == '大客户部'){
        //获取大客户经理
        this.getCustomer()
      }
    },
    //渠道
    // getQd() {
    //   this.form.qdbm = ''
    //   this.form.qd_name = ''
    //   this.$http.post("qd", this.form).then((res) => {
    //     if (res.data.code == 200) {
    //       this.qds.data = res.data.data;
    //     }
    //   });
    // },
    getQd() {
      this.$http.get("qd").then((res) => {
        if (res.data.code == 200) {
          this.qds.all = res.data.data;
        }
      });
    },
    getQdOptions(){
      this.$emit('change','qdbm','')
      this.$emit('change','qd_name','')
      if(this.orderForm.lybm =='' || this.orderForm.city_code==''){
        return false;
      }
      
      this.qds.data = this.qds.all.filter((item)=>{
        return item.sslybm == this.orderForm.lybm && item.szcs == this.orderForm.city_code
      })
      //选择员工转介绍之后 渠道自动变更为员工本人
      // if(this.orderForm.ly_name == '员工转介绍'){
      //   let qd = this.qds.data.find((i)=>i.name == '开发人本人')
      //   if(qd){
      //     this.$emit('change','qdbm',qd.qdbm)
      //     this.$emit('change','qd_name',qd.name)
      //   }
      // }
    },
    onSelectQd(item) {
      this.$emit('change','qdbm',item.qdbm)
      this.$emit('change','qd_name',item.name)
      // this.form.qdbm = item.qdbm
      // this.form.qd_name = item.name
      this.qds.show = false

      //选择渠道后 其它信息全部清除
      this.$emit('change','jsrbm','') // 介绍人病人id
      this.$emit('change','jsrxm','') // 介绍人姓名
      this.$emit('change','jsrsjh','') // 介绍人手机号
      this.$emit('change','yhzgxbm','') // 介绍人关系编码
      this.$emit('change','yhzgx','') // 介绍人关系名称(不传)
      this.$emit('change','dzysbm','') // 点诊医生
      this.$emit('change','dzys_name','') // 点诊医生姓名(不传)
      this.$emit('change','dzzjzlbm','') // 点诊专家助理
      this.$emit('change','dzzjzl_name','') // 点诊专家助理姓名(不传)
      this.$emit('change','ykfrgxbm','') // 与开发人关系id
      this.$emit('change','ykfrgx','') // 与开发人关系名称(不传)
      // this.form.jsrbm = '' // 介绍人病人id
      // this.form.jsrxm = '' // 介绍人姓名
      // this.form.jsrsjh = '' // 介绍人手机号
      // this.form.yhzgxbm = '' // 介绍人关系编码
      // this.form.yhzgx = '' // 介绍人关系名称(不传)
      // this.form.dzysbm = '', // 点诊医生
      // this.form.dzys_name = '' // 点诊医生姓名(不传)
      // this.form.dzzjzlbm = '' // 点诊专家助理
      // this.form.dzzjzl_name = '' // 点诊专家助理姓名(不传)
      // this.form.ykfrgxbm = '' // 与开发人关系id
      // this.form.ykfrgx = '' // 与开发人关系名称(不传)

      //开发人关系更新
      // if(item.name == '开发人直系家属'){
      //   this.kfr_gx.data = this.dicts.filter((item)=>{
      //     return item.flbm == '1022'
      //   })
      // }
      // if(item.name == '开发人旁系家属'){
      //   this.kfr_gx.data = this.dicts.filter((item)=>{
      //     return item.flbm == '1023'
      //   })
      // }
      // if(item.name == '开发人朋友'){
      //   this.kfr_gx.data = this.dicts.filter((item)=>{
      //     return item.flbm == '1024'
      //   })
      // }
      // if(item.name == '开发人其他关系'){
      //   this.kfr_gx.data = this.dicts.filter((item)=>{
      //     return item.flbm == '1025'
      //   })
      //   // this.form.ykfrgx = '其他关系'
      // }
      //客户转介绍 关系更新
      if(item.name == '客户转介绍'){
        this.hz_gx.data = this.dicts.filter((item)=>{
          return item.flbm == '1020'
        })
      }
      //员工转介绍 关系更新
      if(item.name == '员工转介绍'){
        this.hz_gx.data = this.dicts.filter((item)=>{
          return item.flbm == '1021'
        })
      }

      if(item.name === 'TPA'){
        this.getPlatforms()
      }
      if(item.name === '合伙人'){
        this.getPartner()
      }
      
    },
    
    getDzys(){
      this.dzys.show = true
      let form = {
        keywords:this.dzys_key
      }
      this.$http.post('doctor_with_hospital',form).then((res)=>{
        if(res.data.code != 200){
          this.$dialog.alert({
            message: '未查到用户信息。',
            theme: 'round-button',
          }).then(() => {
            return false;
          });
        }
        this.dzys.data = res.data.data
      })
    },
    confirmDzys(item) {
      this.$emit('change','dzysbm',item.rybm)
      this.$emit('change','dzys_name',item.name)
      this.dzys.show = false;
    },
    getZjzl(){
      this.zjzl.show = true
      let form = {
        keywords:this.zjzl_key,
        gw:1008, //岗位 1008 专家助理
      }
      this.$http.post('his_user',form).then((res)=>{
        if(res.data.code != 200){
          this.$dialog.alert({
            message: '未查到用户信息。',
            theme: 'round-button',
          }).then(() => {
            return false;
          });
        }
        this.dzys.data = res.data.data
      })
    },

    confirmZjzl(item) {
      this.$emit('change','dzzjzlbm',item.rybm)
      this.$emit('change','dzzjzl_name',item.name)
      this.zjzl.show = false;
    },
    getYg(){
      this.yg.show = true
      let form = {
        keywords:this.yg_key,
        // gw:1008, //岗位 1008 专家助理
      }
      this.$http.post('his_user',form).then((res)=>{
        if(res.data.code != 200){
          this.$dialog.alert({
            message: '未查到用户信息。',
            theme: 'round-button',
          }).then(() => {
            return false;
          });
        }
        this.yg.data = res.data.data
      })
    },
    confirmYg(item) {
      console.log(item)
      // this.$emit('change','dzysbm',item.rybm)
      // this.$emit('change','dzys_name',item.name)
      this.$emit('change','jsrbm',item.rybm)
      this.$emit('change','jsrxm',item.name)
      this.$emit('change','jsrsjh',item.sjhm)
      this.yg.show = false;
    },
    hz_gx_onselect(item){
      this.$emit('change','yhzgx',item.name)
      this.$emit('change','yhzgxbm',item.zdbm)
      this.hz_gx.show = false
    },
    kfr_gx_onselect(item){
      this.$emit('change','ykfrgx',item.name)
      this.$emit('change','ykfrgxbm',item.zdbm)
      this.kfr_gx.show = false
    },
    async getPartner (keywords){
      const res = await this.$http.get('partners',{params:{keywords:keywords}})
      this.partners = res.data.data.data
    },
    async getCustomer(){
      const res = await this.$http.get('his_user_from_role',{params:{role_id:70}})
      this.customers = res.data.data
    },
    onSelectCustomer(item){
      // console.log(item)
      this.$emit('change','xmfzrybm',item.value)
      this.$emit('change','xmfzrymc',item.name)

    },
    onSelectPartner(item){
      this.$emit('change','hhrid',item.id)
      this.$emit('change','hhr_name',item.name)
      this.$emit('change','hhrtjrbm',item.tjrbm)
      this.$emit('change','hhrtjrxm',item.tjrxm)
    },

    async getPlatforms (){
      const res = await this.$http.get('platforms')
      this.platforms.all = [...res.data.data]
      this.platforms.options = [...res.data.data]

    },

    onSearchPlatform(keywords){
      this.platforms.options.filter(item=>item.includes(keywords))
    },

    onSelectPlatform(item){
      this.$emit('change','hzptid',item.id)
      this.$emit('change','hzpt_name',item.name)
      // this.form.hzptid = item.id
      // this.form.hzpt_name = item.name
      // this.$emit('changeLyQd',this.form)
    }

  },
  // watch:{
  //   'form.city_code': function (val) {
  //     this.$emit('changeLyQd',this.form)
  //   },
  //   'form.lybm': function (val) {
  //     this.$emit('changeLyQd',this.form)
  //   },
  //   'form.qdbm': function (val) {
  //     this.$emit('changeLyQd',this.form)
  //   },
  //   'form.jsrbm': function (val) {
  //     this.$emit('changeLyQd',this.form)
  //   },
  //   'form.jsrxm': function (val) {
  //     this.$emit('changeLyQd',this.form)
  //   },
  //   'form.jsrsjh': function (val) {
  //     this.$emit('changeLyQd',this.form)
  //   },
  //   'form.yhzgxbm': function (val) {
  //     this.$emit('changeLyQd',this.form)
  //   },
  //   'form.ykfrgxbm': function (val) {
  //     this.$emit('changeLyQd',this.form)
  //   },
  //   'form.dzysbm': function (val) {
  //     this.$emit('changeLyQd',this.form)
  //   },
  //   'form.dzzjzlbm': function (val) {
  //     this.$emit('changeLyQd',this.form)
  //   },

  // }
};
</script>
<style scoped>
/* .appointment form {
  padding: 10px;
} */
.van-cell-group__title{
  margin-top:20px;
  margin-bottom:5px;
  font-size:16px;
  color:#07c160;
}
.van-cell{
  padding:16px !important;
}
.time_select {
  background-color: #07c160 !important;
  color: white !important;
}
.notice {
  text-align: left;
}
.jsr .van-cell__value{
  text-align:left;
}
.dzys .van-cell__value{
  text-align:left;
  min-width:70%;
}

</style>
<style>
.appointment .van-popup__close-icon{
  color:#eee !important;
}
.appointment .mu-form-item-label {
  text-align: left !important;
}
.popup_top{
  background-color: #38b6ab;
  /* position:fixed;
  top:0;
  width: 100%;
  height: 150px; */
}
.popup_top .title{
  /* font-weight:bold; */
  color:white;
  font-size:16px;
  line-height:36px;
  padding-top:10px;
}
</style>